import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dropzone from 'react-dropzone'
import CircularProgress from '@mui/material/CircularProgress';
import bookStore from '../../stores/BookStore';
import uiStore from '../../stores/UiStore';
import { uploadImage, deleteImageBlock } from '../../services/BlockService';
import { observer } from 'mobx-react';

function ImageBlockHeader() {
    const [uploading, setUploading] = useState(false);
    const imageBlocks = bookStore.bookData.pages[uiStore.currentPage].image_blocks;
    // find object in array by id
    const [imageBlock] = useState(imageBlocks.find((obj) => obj.id == uiStore.selectedBlock.id));

    const changeTargetWord = (word) => {
        imageBlock.target_word = word;
    };

    const uploadBlockImage = (files, blockId, imageType) =>{
        setUploading(true);
        uploadImage(files, blockId, imageType).then(function(data){
            var index = bookStore.bookData.pages[uiStore.currentPage].image_blocks.map(x => { return x.id; }).indexOf(blockId);
            if (imageType == 'before'){
                bookStore.bookData.pages[uiStore.currentPage].image_blocks[index].before_state.image_url = data.public_url;
            }else{
                bookStore.bookData.pages[uiStore.currentPage].image_blocks[index].after_state.image_url = data.public_url;
            }
            setUploading(false);
        }.bind(this));
    }

    const handleDeleteImageBlock = (event, index) => {
        var index = imageBlocks.findIndex((obj) => obj.id == imageBlock.id);
        deleteImageBlock(imageBlocks[index].id)
        .then(function(data){
            uiStore.selectedBlock.id = null;
            imageBlocks.splice(index, 1);
            bookStore.bookData.pages[uiStore.currentPage].image_blocks = imageBlocks;
            }.bind(this));
    };

    return (
        <Box
            sx={{
            display: 'flex',
            alignItems: 'center',
            width: 'fill',
            borderRadius: 1,
            bgcolor: 'background.paper',
            color: 'text.secondary',
            '& svg': {
                m: 1.5,
            },
            '& hr': {
                mx: 0.5,
            },
            }}
            className="image-block-header"
            >
            <TextField fullWidth size="small" label="Target Word" type="text" 
                value={imageBlock.target_word}
                onChange={(event, attr) => changeTargetWord(event.target.value)}
                onBlur={() => bookStore.saveBook()}/>
            <Stack direction="row" sx={{gap:"8px"}}>
                <Dropzone onDrop={(files, id, ImageType) => {uploadBlockImage(files, imageBlock.id, "before")}} accept="image/png, image/jpeg, image/jpg" >
                    {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        Main image
                    </div>
                    )}
                </Dropzone>
                <img src={imageBlock.before_state.image_url} height="50" width="50"/>
            </Stack>
            <Stack direction="row" sx={{gap:"8px"}}>
                <Dropzone onDrop={(files, id, ImageType) => {uploadBlockImage(files, imageBlock.id, "after")}} accept="image/png, image/jpeg, image/jpg" >
                    {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        On change image
                    </div>
                    )}
                </Dropzone>
                <img src={imageBlock.after_state.image_url} height="50" width="50"/>
            </Stack>
            <Stack direction="row" sx={{gap:"8px"}}>
                <Button size="small"
                onClick={(event, index) => handleDeleteImageBlock(event)}>
                    Delete Block
                </Button>
                {uploading == true ? <CircularProgress /> : '' }
            </Stack>
        </Box>
  );
}
export default observer(ImageBlockHeader);