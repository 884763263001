import * as React from 'react';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { markNotificationsAsRead } from '../services/NotificationService';

const bull = (
    <Box
      component="span"
      sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
      •
    </Box>
  );

export default function NotificationCenter(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        markNotificationsAsRead(props.userId).then(function(data){
            props.notifications.forEach(function (n) {
                n.read = true;
            }
        )}.bind(this));
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <Badge badgeContent={props.notifications.filter(function (n) { return n.read == false}).length} color="primary" onClick={handleClick}>
                <MailIcon color="action" />
            </Badge>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                sx={{minWidth: 275}}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                    {props.notifications.map((notification, index) => {
                        return <Card sx={{ minWidth: 275, borderRadius:"0", marginX: "20px", borderBottom: "solid 1px #B3B6C4", boxShadow:"none" }} key={index}>
                            <CardContent>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom onClick>
                                <Link href={notification.target} underline="none">
                                    {notification.body}
                                </Link>
                                </Typography>
                            </CardContent>
                        </Card>
                    })}
            </Popover>
        </div>
    );
}



