import { observable, makeAutoObservable } from 'mobx';
import React from "react";
import ContentIcon from '@mui/icons-material/ContentCopy';
import AbcIcon from '@mui/icons-material/Abc';
import ImageIcon from '@mui/icons-material/Image';
import bookStore from "../stores/BookStore";
import reactCSS from 'reactcss';
import i18n from '../services/i18n';

class UiStore {
    currentUser = null;
    currentPage = 0;
    activeTab = 0;
    activePageTab = 0;
    prompt = '';
    generatingImage = false;
    pageMessages = [];
    newPageMessage = '';
    actions = [
        { icon: <ContentIcon />, name: 'Add Page', operation: 'add_page' },
        { icon: <AbcIcon />, name: 'Add Text', operation: 'add_text' },
        { icon: <ImageIcon />, name: 'Add Image', operation: 'add_image' },
        // { icon: <MicIcon />, name: 'Add Audio', operation: 'add_audio' },
        // { icon: <EditIcon />, name: 'Add Writing', operation: 'add_writing' }
    ];
    selectedBlock = {id: null, type: null};

    speedDialClicked(e,operation){
        e.preventDefault();
        if(operation=='add_page'){
          bookStore.addPage();
        }else if(operation=='add_text'){
          bookStore.handleAddTextBlock(this.currentPage);
        }else if(operation=='add_image'){
          bookStore.handleAddImageBlock(this.currentPage);
        }else{
          console.log('unknown action');
        }
      };

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true });
    }

    setCurrentUser(value){
        this.currentUser = value;
    }

    setTab(event, newValue) {
        this.activeTab = newValue;
    }

    setPageTab(event, newValue) {
        this.activePageTab = newValue;
    }

    setCurrentPage(value) {
        this.currentPage = value;
    }

    setSelectedBlock(value) {
        this.selectedBlock = value;
    }

    promptChange(value){
        this.prompt = value;
    }

    changePage(dir, pageCount){
        if (this.currentPage + dir < 0 || this.currentPage + dir > pageCount - 1) {
            return;
        }
        this.currentPage = this.currentPage + dir;
    }

    setPageMessages(value){
        this.pageMessages = value;
    }
    appendToPageMessages(value){
        this.pageMessages.push(value);
    }

    setNewPageMessage(value){
        this.newPageMessage = value;
    }

    setLanguage(value){
        // this.language = value;
        i18n.changeLanguage(value);
    }

    fontColors(fontColor){
        return reactCSS({
            'default': {
                color: {
                width: '36px',
                height: '14px',
                borderRadius: '2px',
                backgroundColor: `${ fontColor }`
            },
                swatch: {
                    padding: '5px',
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
            },
                popover: {
                    position: 'absolute',
                    zIndex: '2',
            },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
            },
        });
    }
    fontStyles(){
        return [
            'Helvetica',
            'Comic Sans MS',
            'Courier New',
            'Georgia',
            'Lucida Sans Unicode',
            'Tahoma',
            'Times New Roman',
            'Trebuchet MS',
            'Verdana',
            'Gill Sans',
            'Lucida',
            'Arial Black',
            'Impact',
            'Lucida Console',
            'Monaco',
            'Copperplate',
            'Papyrus',
            'Brush Script MT',
            'cursive',
            'monospace'
        ];
    }
}

export default new UiStore();