export function uploadPageImage(bookId, payload){
    return fetch('/pages/' + bookId + '/upload_page_image', requestOptions('POST', payload))
    .then(response => response.json());
}

export function generateImage(bookId, prompt){
  return fetch('/pages/' + bookId + '/generate_image', jsonRequestOptions('POST', JSON.stringify({prompt: prompt})))
      .then(response => response.json())
}

export function getPageMessages(pageId){
  return fetch('/pages/' + pageId + '/messages', requestOptions('GET', null))
      .then(response => response.json())
}

export function sendPageMessage(conversationId, payload){
  return fetch('/conversations/' + conversationId + '/messages/', jsonRequestOptions('POST', payload))
      .then(response => response.json())
}

function requestOptions(action, body){
    const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
    const requestOptions = {
      method: action,
      headers: { 'X-CSRF-Token': csrf },
      body: body
    };
    return requestOptions;
}

function jsonRequestOptions(action, body){
  const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
  const requestOptions = {
    method: action,
    headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': csrf },
    body: body
  };
  return requestOptions;
}

export function stubPage(pageId, position){
    var ob = {
        id: pageId,
        position: position,
        font: "Helvetica-Bold",
        background_image: "https://betterbookspublic.s3.us-east-1.amazonaws.com/new_page_background.png",
        word_highlighting: "serial",
        text_blocks: [],
        image_blocks: [],
        audio_blocks: [],
        writing_blocks: [],
        page_versions: []
      }
    return ob;
}
