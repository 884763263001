import { Image, Transformer } from 'react-konva';
import React, { useEffect, useRef, useState } from 'react';
import useImage from "use-image";

const URLImage = ({ src, x, y, height, width, isSelected, onSelect, draggable, onChange }) => {
  const [image] = useImage(
    src,
  );
  const shapeRef = React.useRef();
  const trRef = React.useRef();

  React.useLayoutEffect(() => {
    shapeRef.current.cache();
  }, [image]);

  React.useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      trRef.current.setNode(shapeRef.current);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  return (
    <React.Fragment>
      <Image
        image={image}
        x={x}
        y={y}
        crop={{
          x: 80, 
          height: 1100,
          width: 850,
        }}
        height={height}
        width={width}
        onClick={onSelect}
        ref={shapeRef}
        draggable={draggable}
        onDragEnd={e => {
          onChange({
            x: e.target.x(),
            y: e.target.y()
          });
        }}
        onTransformEnd={e => {
          const node = shapeRef.current;

          onChange({
            x: node.x(),
            y: node.y(),
            width: node.width(),
            height: node.height(),
            scaleX: node.scaleX(),
            scaleY: node.scaleY()
          });
        }}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          rotateEnabled={false}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </React.Fragment>
  );
};

export default URLImage;
