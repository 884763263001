import * as React from 'react';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextBlockHeader from '../components/header/TextBlockHeader';
import bookStore from '../stores/BookStore';
import uiStore from '../stores/UiStore';
import { observer } from 'mobx-react';
import ImageBlockHeader from './header/ImageBlockHeader';
import PageHeader from './header/PageHeader';

function BookMenu(){

    return (
        <div className="menu-container">
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              bgcolor: 'background.paper',
              color: 'text.secondary',
              '& svg': {
                m: 1.5,
              },
              '& hr': {
                mx: 0.5,
              },
            }}
          >
            
            <Button onClick={() => bookStore.addPage()} title={'Add Page'}><AddIcon /></Button>
            { uiStore.selectedBlock.id != null ? 
              <div>
                { uiStore.selectedBlock.type == 'text-block' ? <TextBlockHeader /> : null  }
                { uiStore.selectedBlock.type == 'image-block' ? <ImageBlockHeader /> : null  }
              </div>
            : <PageHeader /> }
          </Box>
        </div>
      );
};

export default observer(BookMenu)
