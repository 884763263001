import React from "react";
import BookCanvas from '../components/BookCanvas';
import {PageList} from '../components/PageList';
import BookMenu from '../components/BookMenu';
import BookCreatorHeader from './header/BookCreatorHeader';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import bookStore from "../stores/BookStore";
import uiStore from "../stores/UiStore";
import { observer } from 'mobx-react';
import { toJS } from 'mobx';

class BookCreator extends React.Component {
  constructor(props) {
    super(props);
    bookStore.setBooks(JSON.parse(props.bookJson));
    uiStore.setCurrentUser(props.currentUser);
    this.handleKeypress = this.handleKeypress.bind(this);
    console.log(JSON.parse(props.bookJson));
    uiStore.setLanguage(props.language);
  }

componentDidMount(){
  document.addEventListener("keydown", this.handleKeypress, false);
}
componentWillUnmount(){
  document.removeEventListener("keydown", this.handleKeypress, false);
}

handleKeypress(event){
  if (event.key == 'ArrowUp') {
    uiStore.changePage(-1, bookStore.bookData.pages.length);
  }else if (event.key == 'ArrowDown'){
    uiStore.changePage(1, bookStore.bookData.pages.length);
  }
}

generateRuler(){
  const labels = [];
  for (let i = 0; i < 9; i++) {
      labels.push(<label key={i} >{i*100}</label>);
  }
  return labels;
}

  render () {
    return (<div>
      <Grid container spacing={1}>
        <BookCreatorHeader />
        <Grid item xs={12}>
          <BookMenu />
        </Grid>
        <Grid item xs={12} className="mx-76 bg-light-blue-translucent">
            <Grid container spacing={1}>
              <Grid item xs={2} sx={{padding:'10px'}} >
              <DndProvider backend={HTML5Backend}>
                <PageList
                  bookPages={bookStore.bookData.pages} 
                />
                </DndProvider>
              </Grid>
              <Grid item xs={10} id="ruled" >
                {this.generateRuler()}
                <Box display="flex"
                  justifyContent="start"
                  alignItems="center"
                  minHeight="100vh"
                  paddingTop='24px'
                >
                  {bookStore.bookData.pages.length > 0
                  ? <BookCanvas /> : ''}
                  </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      <SpeedDial ariaLabel="SpeedDial basic example"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
        icon={<SpeedDialIcon />}>
        {uiStore.actions.map((action, index) => (
          <SpeedDialAction
            key={index}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={(e) => {
              uiStore.speedDialClicked(e, action.operation);
            }}
          />
        ))}
    </SpeedDial>
    </div>
  );
  }
}
export default observer(BookCreator)
