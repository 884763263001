import * as React from 'react';
import { useState, useEffect } from 'react'
import { Checkbox, TextField } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';
import { addDeliverable, editDeliverable, deleteDeliverable } from '../services/DeliverableService';

export default function Deliverables(props) {

    const [deliverables, setDeliverables] = useState(props.deliverables);
    const [adding, setAdding] = useState(false);
    const [newDeliverable, setNewDeliverable] = useState('');

    useEffect(() => {
        props.deliverables.forEach(d => {
            d['editing'] = false;
        });
    });

    const handleCheck = (e, id, index) => {
        var deliverable = deliverables[index];
        if (!deliverables[index].completed_at){
            deliverable.completed_at = Date.now();
            editDeliverable(props.projectId, id, {deliverable: deliverable }).then(function(data){
                let newArr = [...deliverables]; 
                newArr[index].completed_at = Date.now();
                setDeliverables(newArr);
            });
        }else{
            deliverable.completed_at = null;
            editDeliverable(props.projectId, id, {deliverable: deliverable }).then(function(data){
                let newArr = [...deliverables]; 
                newArr[index].completed_at = null;
                setDeliverables(newArr);
            });
        }
    };

    const handleTextChange = (e) => {
        setNewDeliverable(e.target.value);
    };

    const add = (event, text) => {
        addDeliverable(props.projectId, {deliverable: {text: text} }).then(function(data){
            setDeliverables(deliverables => [...deliverables, data.deliverable]);
            setAdding(false);
        });
    };

    const saveEditedText = (event, id, index) => {
        var deliverable = deliverables[index];
        editDeliverable(props.projectId, id, {deliverable: deliverable }).then(function(data){
            let newArr = [...deliverables]; 
            newArr[index].editing = false;
            setDeliverables(newArr);
        });
    };

    const editText = (event, id, index, editing) => {
        let newArr = [...deliverables]; 
        newArr[index].editing = editing;
        setDeliverables(newArr);
    };

    const handleTextEdit = (event, id, index) => {
        let newArr = [...deliverables]; 
        newArr[index].text = event.target.value;
        newArr[index].editing = true;
        setDeliverables(newArr);
    };
    
    const remove = (event, id) => {
        deleteDeliverable(props.projectId, id).then(function(data){
            setDeliverables(
                deliverables.filter(d =>
                  d.id != id
                )
            );
        });
    };

    return (
        <div>
            {deliverables.map((deliverable, index) => {
                return (
                    <Grid container key={index}>
                        <Grid item xs={10} className={"d-flex align-items-center gap-2"}>
                            <Checkbox
                                style ={{
                                    color: "#0d6efd",
                                }}
                                checked={deliverable.completed_at ? true : false}
                                onChange={(e) => handleCheck(e, deliverable.id, index)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                            { deliverable.editing ? 
                                <TextField variant="outlined" InputLabelProps={{shrink: true,}} value={deliverable.text} onChange={(e) => handleTextEdit(e, deliverable.id, index)} />
                                :
                                <span className={"slate-gray fs-6"}>{deliverable.text}</span>
                            }
                        </Grid>
                        { deliverable.editing ? 
                            <Grid item xs={2}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <IconButton aria-label="edit" color="primary" onClick={(e) => editText(e, deliverable.id, index, false)}>
                                            <CancelIcon style={{color: "#0d6efd"}}/>
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={6}>
                                    <IconButton variant="contained" onClick={(e) => saveEditedText(e, deliverable.id, index)}><SaveIcon style={{color: "#0d6efd"}}/></IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                            :
                            <Grid item xs={2}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <IconButton aria-label="edit" color="primary" onClick={(e) => editText(e, deliverable.id, index, true)}>
                                            <EditIcon style={{color: "#0d6efd"}}/>
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={6}>
                                    <IconButton aria-label="delete" color="primary" onClick={(e) => remove(e, deliverable.id)}>
                                        <DeleteIcon style={{color: "#0d6efd"}}/>
                                    </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                )
            })}
            {adding ?
                <Grid container>
                    <Grid item xs={8} className={"d-flex align-items-center gap-2"}>
                        <TextField variant="outlined" label="Deliverable" InputLabelProps={{shrink: true,}} value={newDeliverable} onChange={handleTextChange} />
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton variant="contained" onClick={() => setAdding(false)}><CancelIcon style={{color: "#0d6efd"}}/></IconButton>
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton variant="contained"onClick={(e) => add(e, newDeliverable)}><SaveIcon style={{color: "#0d6efd"}}/></IconButton>
                    </Grid>
                </Grid>
            : <Button style={{background: "#0d6efd", color: "#ffffff"}} variant="contained" onClick={() => setAdding(true)}>Add Deliverable</Button>} 
            
        </div>
    );
}
