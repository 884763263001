import * as React from 'react';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Dropzone from 'react-dropzone';
import InlineEdit from 'react-edit-inplace';
import {uploadCover} from '../../services/BookService';
import bookStore from "../../stores/BookStore";
import { observer } from 'mobx-react';
import PublishingModal from './EditBookModal';
import SideBar from '../sidebar/SideBar';
import { useTranslation } from 'react-i18next';

function BookCreatorHeader() {
    const { t, i18n } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClosed = () => setOpen(false);

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      }));

    const uploadCoverImage = (files) =>{
        var formData = new FormData();
        formData.append('file', files[0]);
    
        uploadCover(bookStore.bookData.id, formData).then(function(data){
            console.log(data);
            bookStore.uploadCoverImage(data.public_url);
        }.bind(this));
    }

    return (

        <Grid container spacing={5} className='book-creator-header'>
            <Grid item xs={1} className='item center'>
                 {/* <img src={bookStore.bookData.image_url} height="100" width="100"/> */}
                 <Dropzone onDrop={uploadCoverImage} accept="image/png, image/jpeg, image/jpg">
                    {({ getRootProps, getInputProps }) => (
                        <img src={bookStore.bookData.image_url} height="50" width="50" {...getRootProps({ className: "dropzone" })}/>
                    )}
                </Dropzone> 
            </Grid>
            <Grid item xs={8} className='item left'>
                <h2 className="fs-13">

                    <InlineEdit
                        text={bookStore.bookData.title}
                        change={(data) => bookStore.inlineEditMade(data)}
                        paramName="title"
                    />
                </h2>
                <h5 className="fs-10 slate-gray m-0">by - <InlineEdit
                        text={bookStore.bookData.author}
                        change={(data) => bookStore.inlineEditMade(data)}
                        paramName="author"
                    />
                </h5>
            </Grid>
            <Grid item xs={2} className='item center'>
                <Button onClick={handleOpen} title={'Edit Book'}>{t('Edit Book Info')}</Button>
                <PublishingModal open={open} handleClosed={handleClosed}/>
            </Grid>
            <Grid item xs={1} className='item center'>
                <SideBar />
            </Grid>
        </Grid>
        );
};

export default observer(BookCreatorHeader)
