
import React from "react"
import PropTypes from "prop-types"
import { Stage, Layer, Text, Image , Line} from 'react-konva';
import URLImage from '../components/UrlImage';
import {saveSignal} from '../services/BlockService';

class BookCanvas extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isDrawing: false
    };
    console.log('printing on load');
    if (this.props.bookObject.pages[this.props.currentPage].writing_blocks.length > 0){
      this.props.bookObject.pages[this.props.currentPage].writing_blocks[0].signal = JSON.parse(this.props.bookObject.pages[this.props.currentPage].writing_blocks[0].signal);
    }    
}

  saveBook = (e) => {
    this.props.saveBook(e);
  };

  playAudio = (block, index) => {
    var audio = new Audio(block.audio_url).play();
  };

  handleMouseDown = (e) => {
    if (this.props.bookObject.pages[this.props.currentPage].writing_blocks.length < 1) {
      return;
    }
    this.setState({
      isDrawing: true
    });
    const pos = e.target.getStage().getPointerPosition();
    this.props.bookObject.pages[this.props.currentPage].writing_blocks[0].signal.lines.push([pos.x, pos.y]);
    this.setState({
      bookObject: this.props.bookObject
    });
  };

  handleMouseMove = (e) => {
    // no drawing - skipping
    if (!this.state.isDrawing) {
      return;
    }
    const stage = e.target.getStage();
    const point = stage.getPointerPosition();
    let lastLine = this.props.bookObject.pages[this.props.currentPage].writing_blocks[0].signal.lines[this.props.bookObject.pages[this.props.currentPage].writing_blocks[0].signal.lines.length - 1];
    // add point
    lastLine = lastLine.concat([point.x, point.y]);

    // replace last
    this.props.bookObject.pages[this.props.currentPage].writing_blocks[0].signal.lines[this.props.bookObject.pages[this.props.currentPage].writing_blocks[0].signal.lines.length - 1] = lastLine;
    // var theLines = this.state.lines.splice(this.state.lines.length - 1, 1, lastLine);
    // this.props.bookObject.pages[this.props.currentPage].writing_blocks[0].signal = lines;
    this.setState({
      bookObject: this.props.bookObject
    });
  };

  handleMouseUp = () => {
    if (this.props.bookObject.pages[this.props.currentPage].writing_blocks.length < 1) {
      return;
    }
    this.setState({
      isDrawing: false
    });
    // save signal
    // this.props.saveBook();
    console.log('printing on up');
    console.log(this.props.bookObject.pages[this.props.currentPage].writing_blocks[0].signal);

    saveSignal(this.props.bookObject.pages[this.props.currentPage].writing_blocks[0].id, 
      JSON.stringify({writing_block:{signal:this.props.bookObject.pages[this.props.currentPage].writing_blocks[0].signal}}))
    .then(function(data){
        console.log(data);
    }.bind(this));
  };

  render () {
    return (
      <Stage 
        width={850} height={1100}
        onMouseDown={this.handleMouseDown}
        onMousemove={this.handleMouseMove}
        onMouseup={this.handleMouseUp}>
        <Layer>
          <URLImage src={this.props.bookObject.pages[this.props.currentPage].background_image} width={850} height={1100} onClick={this.backgroundClick} draggable={false}/>
          {this.props.bookObject.pages[this.props.currentPage].text_blocks.map((value, index) => {
            return <Text 
            key={index}
            text={value.body} 
            x={parseFloat(value.x)} 
            y={parseFloat(value.y)} 
            width={parseFloat(value.width)}
            wrap="word"
            fontSize={value.font_size} 
            fill={value.font_color} 
            stroke={value.stroke_color} 
            strokeWidth={value.stroke_width / 2}
            onDragEnd={e => {
              this.props.bookObject.pages[this.props.currentPage].text_blocks[index].x = e.target.x();
              this.props.bookObject.pages[this.props.currentPage].text_blocks[index].y = e.target.y();
              this.setState({
                bookObject: this.props.bookObject
              });
              this.saveBook();
            }}
            draggable />
          })}
          {this.props.bookObject.pages[this.props.currentPage].image_blocks.map((value, index) => {
            return <URLImage 
              key={index}
              src={this.props.bookObject.pages[this.props.currentPage].image_blocks[index].triggered ? value.after_state.image_url : value.before_state.image_url} 
              x={parseFloat(value.before_state.x)} 
              y={parseFloat(value.before_state.y)} 
              width={parseFloat(value.before_state.width)} 
              height={parseFloat(value.before_state.height)}
              onClick={e => {
                this.props.bookObject.pages[this.props.currentPage].image_blocks[index].triggered = !this.props.bookObject.pages[this.props.currentPage].image_blocks[index].triggered;
                this.setState({
                  bookObject: this.props.bookObject
                });
              }}
              draggable={true}
              onDragEnd={e => {
                this.props.bookObject.pages[this.props.currentPage].image_blocks[index].before_state.x = e.target.x();
                this.props.bookObject.pages[this.props.currentPage].image_blocks[index].after_state.x = e.target.x();
                this.props.bookObject.pages[this.props.currentPage].image_blocks[index].before_state.y = e.target.y();
                this.props.bookObject.pages[this.props.currentPage].image_blocks[index].after_state.y = e.target.y();
                this.setState({
                  bookObject: this.props.bookObject
                });
                this.saveBook();
              }}/>
          })}
          {this.props.bookObject.pages[this.props.currentPage].audio_blocks.map((value, index) => {
            return <Text 
            key={index}
            text={"Play"} 
            x={parseFloat(value.x)} 
            y={parseFloat(value.y)} 
            width={100}
            fontSize={20} 
            fill={"#ffffff"} 
            stroke={"#000000"} 
            strokeWidth={1}
            onClick={e => {
              this.playAudio(value, index);
            }}
            onDragEnd={e => {
              this.props.bookObject.pages[this.props.currentPage].audio_blocks[index].x = e.target.x();
              this.props.bookObject.pages[this.props.currentPage].audio_blocks[index].y = e.target.y();
              this.setState({
                bookObject: this.props.bookObject
              });
              this.saveBook();
            }}
            draggable />
          })}
        </Layer>
        {this.props.bookObject.pages[this.props.currentPage].writing_blocks.length > 0 ?
          <Layer>
            {this.props.bookObject.pages[this.props.currentPage].writing_blocks[0].signal.lines.map((line, i) => (
              <Line
                key={i}
                points={line}
                stroke="#000000"
                strokeWidth={5}
                tension={0.5}
                lineCap="round"
                lineJoin="round"
                globalCompositeOperation={'source-over'}
              />
            ))}
          </Layer>
          : null }
      </Stage>
    );
  }
}

BookCanvas.propTypes = {
  bookObject: PropTypes.object,
  currentPage: PropTypes.number
};
export default BookCanvas


