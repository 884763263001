import React from "react"
import Button from '@mui/material/Button';
// import AudioReactRecorder, { RecordState } from 'audio-react-recorder'

class AudioBlockEditor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            recordState: null,
            audioData: props.audioBlock.audio_url,
            audio: props.audioBlock.audio_url,
            uploading: false
        };
        this.uploadAudio = this.uploadAudio.bind(this);
        this.onStop = this.onStop.bind(this);
        // var audio = new Audio(props.audioBlock.audio_url)
      }

    start = () => {
        this.setState({
            recordState: RecordState.START
        })
    }

    stop = () => {
        this.setState({
            recordState: RecordState.STOP
        })
    }

    //audioData contains blob and blobUrl
    onStop = (audioData) => {
        this.setState({
            audioData: audioData
        })
        this.uploadAudio(audioData.blob, this.props.audioBlock.id);
    }

    uploadAudio(file, blockId){
        this.setState({uploading: true});
        var formData = new FormData();
        formData.append('file', file);
        const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
        const requestOptions = {
            method: 'POST',
            headers: { 'X-CSRF-Token': csrf },
            body: formData
        };

        fetch('/audio_blocks/'+ blockId +'/upload_block_audio', requestOptions)
        .then(response => response.json())
        .then(function(data){
            this.props.setAudioBlockUrl(this.props.index, data.public_url);
            this.setState({uploading: false});
        }.bind(this));
    }

    deleteAudioBlock = (event, index) => {
        this.props.deleteAudioBlock(index);
    };

    render () {
        const { recordState } = this.state

        return (
            <div>
                <audio id='audio'
                controls
                src={this.props.audioBlock.audio_url}
            >
            </audio>
            <AudioReactRecorder state={this.state.recordState} onStop={this.onStop} />
    
            <button onClick={this.start}>Start</button>
            <button onClick={this.stop}>Stop</button>
            <Button size="small"
                onClick={(event, index) => this.deleteAudioBlock(event, this.props.index.toString())}>
                    Delete Block
                </Button>
        </div>
        );
    }
}

export default AudioBlockEditor
