import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

import {
    IconFlagUS,
    IconFlagES
} from 'material-ui-flags';
import i18n from '../services/i18n';

function LanguageSelect(){
    const [locale, setLocale] = React.useState('en');

    useEffect(() => {
        var url = new URL(window.location.href);
        var search_params = url.searchParams;
        var locale = search_params.get('locale');
        setLocale(locale);
    });

    const changeLanguage = (event) => {
        var url = new URL(window.location.href);
        var search_params = url.searchParams;
        search_params.set('locale', event.target.value);
        url.search = search_params.toString();
        var new_url = url.toString();
        window.location.href = new_url;
        i18n.changeLanguage(event.target.value);
    };

    return (
        <div>
        <FormControl fullWidth>
            <Select
                value={locale}
                label="Locale"
                onChange={changeLanguage}
            >
                <MenuItem value={'en'}><IconFlagUS /></MenuItem>
                <MenuItem value={'es'}><IconFlagES /></MenuItem>
            </Select>
            </FormControl>
      </div>
      );
};

export default LanguageSelect
