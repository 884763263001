import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {potentialTags, updateTags, bookTags} from '../services/BookService';

export default function Tags(props) {

  function addTags(event, value){
    updateTags(props.bookId, JSON.stringify({tags: value})).then(function(data){
    }.bind(this));
  }

  function formattedTags(){
    var formatted = []
    for (let i = 0; i < props.tags.length; i++) {
      formatted.push({name: props.tags[i]});
    }
    return formatted;
  }

  return (
    <Autocomplete
      multiple
      id="tags"
      options={candidateTags}
      getOptionLabel={(option) => option.name}
      defaultValue={formattedTags}
      onChange={addTags}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label="Tags"
          placeholder="Tags"
        />
      )}
    />
  );
}

var candidateTags = [];
potentialTags().then(function(data){
  candidateTags = data.tags;
}.bind(this));
