export function markNotificationsAsRead(userId){
    return fetch('/users/' + userId + '/notfications/mark_as_read', jsonRequestOptions('POST', null))
    .then(response => response.json());
}

function jsonRequestOptions(action, body){
  const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
  const requestOptions = {
    method: action,
    headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': csrf },
    body: body
  };
  return requestOptions;
}
