import React from "react";
import { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import bookStore from '../stores/BookStore';
import uiStore from "../stores/UiStore";
import { observer } from 'mobx-react';
import { deletePage } from "../services/BookService";

export const PageThumbnail = observer(({ id, background_image, index, movePage }) => {

    const Item = styled(Paper)(({ theme }) => ({
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    }));
    const ref = useRef(null)
    const [{ handlerId }, drop] = useDrop({
        accept: ItemTypes.CARD,
        collect(monitor) {
        return {
            handlerId: monitor.getHandlerId(),
        }
        },
        hover(item, monitor) {
        if (!ref.current) {
            return
        }
        const dragIndex = item.index
        const hoverIndex = index
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
            return
        }
        // Determine rectangle on screen
        const hoverBoundingRect = ref.current?.getBoundingClientRect()
        // Get vertical middle
        const hoverMiddleY =
            (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
        // Determine mouse position
        const clientOffset = monitor.getClientOffset()
        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top
        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%
        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
            return
        }
        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
            return
        }
        // Time to actually perform the action
        movePage(dragIndex, hoverIndex)
        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.index = hoverIndex
        },
    })
    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.CARD,
        item: () => {
        return { id, index }
        },
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult()
            if (item && dropResult) {
                bookStore.updatePagePosition(item);
            }
        },
        collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        }),
    })
    const opacity = isDragging ? 0 : 1
    drag(drop(ref))

    function handleDeletePage(){
        var result = confirm("Want to delete?");
        if (result == true) {
             // handling deleting the first or last page
            if (bookStore.bookData.pages.length > 1){
                if (uiStore.currentPage > 0){
                    uiStore.setCurrentPage(uiStore.currentPage - 1);
                }else{
                    uiStore.setCurrentPage(uiStore.currentPage + 1);
                }
            }
            bookStore.deletePage(index);
        }
    }

    return (
        <div ref={ref} style={{ opacity }}
            data-handler-id={handlerId}
            className={`page-thumbnail ${uiStore.currentPage == index ? "selected-slide" : ""}`}>
                <img src={`${background_image}`} onClick={() => uiStore.setCurrentPage(index)} loading="lazy" height={'100%'} width={'100%'}/>
                <Button onClick={() => handleDeletePage()} title={'Delete Page'}><DeleteIcon fontSize="small" /></Button>
        </div>
    )
})

export const ItemTypes = {
    CARD: 'card',
}
