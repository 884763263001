import React from 'react';
import { Text, Transformer } from 'react-konva';
import { observer } from 'mobx-react';

const TextBlock = ({ blockProps, isSelected, onSelect, onChange, setTextValue }) => {
    const shapeRef = React.useRef();
    const trRef = React.useRef();
  
    React.useEffect(() => {
      if (isSelected) {
        // we need to attach transformer manually
        trRef.current.nodes([shapeRef.current]);
        trRef.current.getLayer().batchDraw();
      }
    }, [isSelected]);

    const doubleClickHandler = (e, blockProps) => {
      const stage = e.target.getStage();
      const layer = stage.findOne('.layer');
      const textNode = e.target;

      // hide text node and transformer:
      textNode.hide();
      // tr.hide();

      // // create textarea over canvas with absolute position
      // // first we need to find position for textarea
      // // how to find it?

      // // at first lets find position of text node relative to the stage:
      var textPosition = textNode.absolutePosition();

      // // so position of textarea will be the sum of positions above:
      var areaPosition = {
        x: stage.container().offsetLeft + textPosition.x,
        y: stage.container().offsetTop + textPosition.y,
      };

      // // create textarea and style it
      var textarea = document.createElement('textarea');
      document.body.appendChild(textarea);

      // // apply many styles to match text on canvas as close as possible
      // // remember that text rendering on canvas and on the textarea can be different
      // // and sometimes it is hard to make it 100% the same. But we will try...
      textarea.value = textNode.text();
      textarea.style.position = 'absolute';
      textarea.style.top = areaPosition.y + 'px';
      textarea.style.left = areaPosition.x + 'px';
      textarea.style.width = textNode.width() - textNode.padding() * 2 + 'px';
      textarea.style.height =
        textNode.height() - textNode.padding() * 2 + 5 + 'px';
      textarea.style.fontSize = textNode.fontSize() + 'px';
      textarea.style.border = 'none';
      textarea.style.padding = '0px';
      textarea.style.margin = '0px';
      textarea.style.overflow = 'hidden';
      textarea.style.background = 'none';
      textarea.style.outline = 'none';
      textarea.style.resize = 'none';
      textarea.style.lineHeight = textNode.lineHeight();
      textarea.style.fontFamily = textNode.fontFamily();
      textarea.style.transformOrigin = 'left top';
      textarea.style.textAlign = textNode.align();
      textarea.style.color = textNode.fill();
      // rotation = textNode.rotation();
      // var transform = '';
      // if (rotation) {
      //   transform += 'rotateZ(' + rotation + 'deg)';
      // }

      var px = 0;

      // // reset height
      textarea.style.height = 'auto';
      // // after browsers resized it we can set actual value
      textarea.style.height = textarea.scrollHeight + 3 + 'px';

      textarea.focus();

      function removeTextarea() {
        textarea.parentNode.removeChild(textarea);
        window.removeEventListener('click', handleOutsideClick);
        textNode.show();
        // tr.show();
        // tr.forceUpdate();
      }

      function setTextareaWidth(newWidth) {
        if (!newWidth) {
          // set width for placeholder
          newWidth = textNode.placeholder.length * textNode.fontSize();
        }
        // some extra fixes on different browsers
        var isSafari = /^((?!chrome|android).)*safari/i.test(
          navigator.userAgent
        );
        var isFirefox =
          navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
        if (isSafari || isFirefox) {
          newWidth = Math.ceil(newWidth);
        }

        var isEdge =
          document.documentMode || /Edge/.test(navigator.userAgent);
        if (isEdge) {
          newWidth += 1;
        }
        textarea.style.width = newWidth + 'px';
      }

      textarea.addEventListener('keydown', function (e) {
        // hide on enter
        // but don't hide on shift + enter
        if (e.keyCode === 13 && !e.shiftKey) {
          textNode.text(textarea.value);
          setTextValue(textarea.value);
          removeTextarea();
        }
        // on esc do not set value back to node
        if (e.keyCode === 27) {
          removeTextarea();
        }
      });

      textarea.addEventListener('keydown', function (e) {
        // scale = textNode.getAbsoluteScale().x;
        // setTextareaWidth(textNode.width() * scale);
        textarea.style.height = 'auto';
        textarea.style.height =
          textarea.scrollHeight + textNode.fontSize() + 'px';
      });

      function handleOutsideClick(e) {
        if (e.target !== textarea) {
          textNode.text(textarea.value);
          setTextValue(textarea.value);
          removeTextarea();
        }
      }
      setTimeout(() => {
        window.addEventListener('click', handleOutsideClick);
      });
    }
  
    return (
      <React.Fragment>
        <Text
          onClick={onSelect}
          onDblClick={(arg) => {
            doubleClickHandler(arg, blockProps);
          }}
          onTap={onSelect}
          ref={shapeRef}
          text={blockProps.body}
          x={parseFloat(blockProps.x)}
          y={parseFloat(blockProps.y)}
          width={parseFloat(blockProps.width)}
          wrap="word"
          fontSize={blockProps.font_size}
          fontFamily={blockProps.font_family}
          align={blockProps.text_align}
          fill={blockProps.font_color} 
          stroke={blockProps.stroke_color} 
          strokeWidth={blockProps.stroke_width / 2}
          draggable
          onDragEnd={(e) => {
            onChange({
              ...blockProps,
              x: e.target.x(),
              y: e.target.y(),
            });
          }}
          onTransformEnd={(e) => {
            const node = shapeRef.current;
            // we will reset it back
            // node.scaleX(1);
            // node.scaleY(1);
            node.fontSize(Math.round(node.fontSize() * node.scaleX()));
            node.width(Math.round(node.width() * node.scaleX()));
            node.scale({x: 1, y: 1});
            onChange({
              // ...blockProps,
              x: node.x(),
              y: node.y(),
              width: node.width(),
              fontSize: node.fontSize(),
            });
          }}
        />
        {isSelected && (
          <Transformer
            ref={trRef}
            enabledAnchors={['middle-left','middle-right']}
            keepRatio={true}
            rotateEnabled={false}
            boundBoxFunc={(oldBox, newBox) => {
              // limit resize
              if (newBox.width < 5 || newBox.height < 5) {
                return oldBox;
              }
              return newBox;
            }}
          />
        )}
      </React.Fragment>
    );
  };
export default observer(TextBlock)