import { observable, makeAutoObservable, toJS } from 'mobx';
import { updateBook, addPage, deletePage } from '../services/BookService';
import {uploadPageImage, stubPage } from '../services/PageService';
import { addTextBlock, addImageBlock } from '../services/BlockService';

class BookStore {

  bookData = {
    description: null,
    image_url: null
  }

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  // Book methods
  setBooks(bookJson) { // is this method needed or can it be set directly?
    this.bookData = bookJson;
    console.log(this.bookData);
  }

  setDescription(value) {
    this.bookData['description'] = value;
  }

  uploadCoverImage(public_url){
    this.bookData['image_url'] = public_url;
  }

  setTags(value) {
    this.bookData.tags = value;
  }

  inlineEditMade(data) {
    var key = Object.keys(data)[0];
    this.bookData[key] = Object.values(data)[0];
    this.saveBook();
}

  *saveBook(){
    var bookData = this.transformBookData(this.bookData);
    const response = yield updateBook(this.bookData.id, JSON.stringify(bookData))
    if (response.ok) console.log('success');
  }

  transformBookData(bookData) {
    var bd = bookData;
    bd['pages_attributes'] = bookData['pages'];
      for (let i = 0; i < bookData.pages.length; i++) {
        bd.pages[i]['text_blocks_attributes'] = bookData.pages[i]['text_blocks'];
        bd.pages[i]['image_blocks_attributes'] = bookData.pages[i]['image_blocks'];
        bd.pages[i]['audio_blocks_attributes'] = bookData.pages[i]['audio_blocks'];
        bd.pages[i]['writing_blocks_attributes'] = bookData.pages[i]['writing_blocks'];
        for (let j = 0; j < bookData.pages[i]['image_blocks_attributes'].length; j++) {
          bd.pages[i]['image_blocks_attributes'][j]['before_state_attributes'] = bookData.pages[i]['image_blocks_attributes'][j]['before_state'];
          bd.pages[i]['image_blocks_attributes'][j]['after_state_attributes'] = bookData.pages[i]['image_blocks_attributes'][j]['after_state'];
        }
      }
      return bd;
  }

  // Page methods
  addPage(){
    addPage(this.bookData.id).then(function(data){
      var page = stubPage(data.page.id, data.page.position);
      this.bookData.pages.push(page);
      // TODO set current page to new page?
    }.bind(this));
  }

  deletePage(index){
    deletePage(this.bookData.pages[index].id).then(function(data){
        this.bookData.pages.splice(index, 1);
      }.bind(this));
  }

  updatePagePosition(item){
    // find page index in pages by id
    var pagePos = this.bookData.pages.map(function(x) {return x.id; }).indexOf(item.id);
    // loop down from index to 0
    for(let i=item.index; i > 0; i-- ){
      this.bookData.pages[i].position = i-1; // set page index to one less than the previous
    }
    // loop up from index to end
    for(let i=item.index; i < this.bookData.pages.length; i++ ){
      this.bookData.pages[i].position = pagePos + i; // set page index to one more than the previous
    }
    // set position to item index
    this.bookData.pages[pagePos].position = item.index;
    this.bookData.pages = this.bookData.pages.sort((a,b) => a.position - b.position);;
    this.saveBook();
  }

  uploadPageImage(files, currentPage){
    var formData = new FormData();
    formData.append('file', files[0]);
    uploadPageImage(this.bookData.pages[currentPage].id, formData).then(function(data){
      this.bookData.pages[currentPage].background_image = data.public_url;
      this.bookData.pages[currentPage].page_versions.push(data.page_version);
    }.bind(this));
  }

  // text block methods
  handleAddTextBlock(currentPage){
    addTextBlock(this.bookData.pages[currentPage].id).then(function(data){;
      this.bookData.pages[currentPage].text_blocks.push(data.text_block);
    }.bind(this));
  }

  handlePageChange(value, index, attr){
    this.bookData.pages[index][attr] = value;
  }

  setBackgroundImage(currentPage, url){
    this.bookData.pages[currentPage].background_image = url;
    this.saveBook();
  }

  setTextBlockAttr(currentPage, value, index, attr){
    this.bookData.pages[currentPage].text_blocks[index][attr] = value;
    this.saveBook();
  }

  setImageBlockAttr(currentPage, value, index, state, attr){
    this.bookData.pages[currentPage].image_blocks[index][state][attr] = value;
    this.saveBook();
  }

  // image block methods
  handleAddImageBlock(currentPage){
    addImageBlock(this.bookData.pages[currentPage].id).then(function(data){
        var block = JSON.parse(data.image_block)
        var imageBlock = {
          id: block.id,
          target_word: block.target_word,
          triggered: false,
          before_state: block.before_state,
          after_state: block.after_state
        }
        this.bookData.pages[currentPage].image_blocks.push(imageBlock);
      }.bind(this));
    }


    // additional things to implement post MVP

      // addAudioBlock(){
  //   addAudioBlock(this.state.bookObject.pages[this.state.currentPage].id).then(function(data){
  //     var audioBlock = data.audio_block;
  //     var ob = this.state.bookObject;
  //     ob.pages[this.state.currentPage].audio_blocks.push(audioBlock);
  //     this.setState({bookObject: ob});
  //   }.bind(this));
  // }

  // setAudioBlockUrl(index, url){
  //   var currentPage = this.state.currentPage;
  //   var audioBlocks = this.state.bookObject.pages[currentPage].audio_blocks;
  //   audioBlocks[index].audio_url = url;
  //   var ob = this.state.bookObject;
  //   ob.pages[currentPage].audio_blocks = audioBlocks;
  //   this.setState({bookObject: ob});
  // }

  // deleteAudioBlock(index){
  //   var currentPage = this.state.currentPage;
  //   var audioBlocks = this.state.bookObject.pages[currentPage].audio_blocks;

  //   deleteAudioBlock(audioBlocks[index].id)
  //   .then(function(data){
  //       var ob = this.state.bookObject;
  //       audioBlocks.splice(index, 1);
  //       ob.pages[currentPage].audio_blocks = audioBlocks;
  //       this.setState({bookObject: ob});
  //     }.bind(this));
  // }

  // addWritingBlock(){
  //   addWritingBlock(this.state.bookObject.pages[this.state.currentPage].id).then(function(data){
  //     var writingBlock = data.writing_block;
  //     console.log(data);
  //     writingBlock.signal = JSON.parse(writingBlock.signal);
  //     var ob = this.state.bookObject;
  //     ob.pages[this.state.currentPage].writing_blocks.push(writingBlock);
  //     this.setState({bookObject: ob});
  //   }.bind(this));
  // }

  // deleteWritingBlock(index){
  //   var currentPage = this.state.currentPage;
  //   var writingBlocks = this.state.bookObject.pages[currentPage].writing_blocks;
  //   deleteWritingBlock(writingBlocks[index].id)
  //     .then(function(data){
  //       writingBlocks.splice(index, 1);
  //       var ob = this.state.bookObject;
  //       ob.pages[currentPage].writing_blocks = writingBlocks;
  //       this.setState({bookObject: ob});
  //     }.bind(this));
  // }

  // deleteWritingBlock(index){
  //   var currentPage = this.state.currentPage;
  //   var writingBlocks = this.state.bookObject.pages[currentPage].writing_blocks;

  //   deleteWritingBlock(writingBlocks[index].id)
  //   .then(function(data){
  //       var ob = this.state.bookObject;
  //       writingBlocks.splice(index, 1);
  //       ob.pages[currentPage].writing_blocks = writingBlocks;
  //       this.setState({bookObject: ob});
  //     }.bind(this));
  // }

  // clearWriting() {
  //   var currentPage = this.state.currentPage;
  //   var writingBlocks = this.state.bookObject.pages[currentPage].writing_blocks;
  //   writingBlocks[0].signal.lines = [];
  //   var ob = this.state.bookObject;
  //   ob.pages[currentPage].writing_blocks = writingBlocks;
  //   this.setState({bookObject: ob});
  //   bookStore.saveBook();
  // }

  // makePageDrawable() {
  //   var currentPage = this.state.currentPage;
  //   var ob = this.state.bookObject;
  //   ob.pages[currentPage].drawable = !ob.pages[currentPage].drawable;
  //   this.setState({bookObject: ob});
  //   bookStore.saveBook();
  // }

  // clearWriting() {
  //   var currentPage = this.state.currentPage;
  //   var writingBlocks = this.state.bookObject.pages[currentPage].writing_blocks;
  //   writingBlocks[0].signal.lines = [];
  //   var ob = this.state.bookObject;
  //   ob.pages[currentPage].writing_blocks = writingBlocks;
  //   this.setState({bookObject: ob});
  //   bookStore.saveBook();
  // }

  // makePageDrawable() {
  //   var currentPage = this.state.currentPage;
  //   var ob = this.state.bookObject;
  //   ob.pages[currentPage].drawable = !ob.pages[currentPage].drawable;
  //   this.setState({bookObject: ob});
  //   bookStore.saveBook();
  // }

}

export default new BookStore();