export async function updateBook(bookId, payload){
    return new Promise((resolve) => {
        fetch('/books/'+ bookId, requestOptions('PUT', payload))
      });
}

export function addPage(bookId){
    return fetch('/books/' + bookId + '/pages/', requestOptions('POST', null))
        .then(response => response.json())
}

export function deletePage(bookId){
    return fetch('/pages/' + bookId, requestOptions('DELETE', null))
        .then(response => response.json())
}

export function potentialTags(){
    return fetch('/potential_tags', requestOptions('GET', null))
        .then(response => response.json())
}

export function getVersions(bookId){
    return fetch('/books/' + bookId + '/publish_versions', requestOptions('GET', null))
        .then(response => response.json())
}

export function postVersion(bookId){
    return fetch('/books/' + bookId + '/publish_versions', requestOptions('POST', null))
        .then(response => response.json())
}

export function updateTags(bookId, payload){
    return fetch('/books/'+ bookId+ '/update_tags', requestOptions('PUT', payload))
        .then(response => response.json())
}

export function uploadCover(bookId, formData){
    const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
    const requestOptions = {
      method: 'POST',
      headers: { 'X-CSRF-Token': csrf },
      body: formData
    };
    return fetch('/books/' + bookId + '/upload_cover_image', requestOptions)
        .then(response => response.json())
}

function requestOptions(action, body){
    const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
    const requestOptions = {
      method: action,
      headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': csrf },
      body: body
    };
    return requestOptions;
}
