import * as ActionCable from '@rails/actioncable'
window.App || (window.App = {});
window.App.cable = ActionCable.createConsumer();
App.cable.subscriptions.create({ channel: "MessagesChannel", id: "<%= @conversation.id %>" }, {
  connected() {
    console.log("Connected to the channel:", this);
  },
  disconnected() {
    console.log("Disconnected");
  },
  received(data) {
    console.log("Received some data:", data);
  }
});

App.cable.subscriptions.create({ channel: "ConversationChannel", id: "<%= @project.conversation.id %>" }, {
received(comment) {
  el = document.createElement('li');
  el.innerHTML = comment;
  document.querySelector('ul').appendChild(el)
}
});
