import React from "react"
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import CardActions from '@mui/material/CardActions';
import { SketchPicker } from 'react-color';
import reactCSS from 'reactcss';
import { addTextBlock, deleteTextBlock } from '../services/BlockService';
import bookStore from "../stores/BookStore";
import uiStore from "../stores/UiStore";
import { observer } from 'mobx-react';

class TextBlockEditor extends React.Component {

state = {
    displayFontColorPicker: false,
    displayStrokeColorPicker: false
};

handleFontColorClick = () => {
    this.setState({ displayFontColorPicker: !this.state.displayFontColorPicker })
};

handleFontColorClose = () => {
    this.setState({ displayFontColorPicker: false })
};

handleFontColorChange = (color) => {
    this.setState({ color: color.rgb })
};

handleStrokeColorClick = () => {
    this.setState({ displayStrokeColorPicker: !this.state.displayStrokeColorPicker })
};

handleStrokeColorClose = () => {
    this.setState({ displayStrokeColorPicker: false })
};

handleStrokeColorChange = (color) => {
    this.setState({ color: color.rgb })
};

textBlockChange = (value, index, attr) => {
    bookStore.bookData.pages[uiStore.currentPage].text_blocks[this.props.index][attr] = value;
};

deleteTextBlock = (event, index) => {
    var textBlocks = bookStore.bookData.pages[uiStore.currentPage].text_blocks;
    deleteTextBlock(textBlocks[index].id)
    .then(function(data){
        textBlocks.splice(index, 1);
        bookStore.bookData.pages[uiStore.currentPage].text_blocks = textBlocks;
    }.bind(this));
};

render () {

    const fontColorStyles = reactCSS({
        'default': {
          color: {
            width: '36px',
            height: '14px',
            borderRadius: '2px',
            backgroundColor: `${ this.props.textBlock.font_color }`
          },
          swatch: {
            padding: '5px',
            background: '#fff',
            borderRadius: '1px',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'inline-block',
            cursor: 'pointer',
          },
          popover: {
            position: 'absolute',
            zIndex: '2',
          },
          cover: {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
          },
        },
      });

      const strokeColorStyles = reactCSS({
        'default': {
          color: {
            width: '36px',
            height: '14px',
            borderRadius: '2px',
            backgroundColor: `${ this.props.textBlock.stroke_color }`
          },
          swatch: {
            padding: '5px',
            background: '#fff',
            borderRadius: '1px',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'inline-block',
            cursor: 'pointer',
          },
          popover: {
            position: 'absolute',
            zIndex: '2',
          },
          cover: {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
          },
        },
      });

    return (
        <Card sx={{ minWidth: 275 }} variant="outlined">
            <CardContent>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Text Block
                </Typography>
                <Stack spacing={2}>
                    <TextField fullWidth size="small" type="text" label="Text Content" 
                        name={this.props.index.toString()}
                        value={this.props.textBlock.body} 
                        onChange={(event, index, attr) => this.textBlockChange(event.target.value, this.props.index.toString(), 'body')}
                        onBlur={() => bookStore.saveBook()}
                    />
                    <Typography sx={{ fontSize: 6 }} color="text.secondary" gutterBottom>
                        Text Width
                    </Typography>
                    <Slider aria-label="Width" value={parseInt(this.props.textBlock.width)}
                        name={this.props.index.toString()}
                        onChange={(event, index, attr) => this.textBlockChange(event.target.value, this.props.index.toString(), 'width')}
                        onChangeCommitted={() => bookStore.saveBook()}
                        min={50} max={1000}
                    />
                    <Typography sx={{ fontSize: 6 }} color="text.secondary" gutterBottom>
                        Text Size
                    </Typography>
                    <Slider aria-label="Size"
                        value={this.props.textBlock.font_size}
                        onChange={(event, index, attr, autoSave) => this.textBlockChange(event.target.value, this.props.index.toString(), 'font_size')}
                        onChangeCommitted={() => bookStore.saveBook()} min={6} max={200}
                    />
                    <div>
                        <div style={ fontColorStyles.swatch } onClick={ this.handleFontColorClick }>
                            Font Color: <div style={ fontColorStyles.color } />
                            </div>
                            { this.state.displayFontColorPicker ? <div style={ fontColorStyles.popover }>
                            <div style={ fontColorStyles.cover } onClick={ this.handleFontColorClose }/>
                                <SketchPicker
                                    color={ this.state.fontColor }
                                    onChange={(color, index) => this.textBlockChange(color.hex, this.props.index.toString(), 'font_color')}
                                    onChangeComplete={() => bookStore.saveBook()}
                                />
                        </div> : null }
                    </div>
                    <Typography sx={{ fontSize: 6 }} color="text.secondary" gutterBottom>
                        Text Stroke Width {this.props.textBlock.stroke_width}
                    </Typography>
                    <Slider aria-label="Stroke Width" value={parseInt(this.props.textBlock.stroke_width)}
                        onChange={(event, index, attr) => this.textBlockChange(event.target.value, this.props.index.toString(), 'stroke_width')}
                        onChangeCommitted={() => bookStore.saveBook()}
                        min={0}
                        max={10}
                    />
                    <div>
                        <div style={ strokeColorStyles.swatch } onClick={ this.handleStrokeColorClick }>
                            Stroke Color: <div style={ strokeColorStyles.color } />
                            </div>
                            { this.state.displayStrokeColorPicker ? <div style={ strokeColorStyles.popover }>
                            <div style={ strokeColorStyles.cover }
                                onClick={ this.handleStrokeColorClose }/>
                                <SketchPicker
                                    color={ this.state.strokeColor }
                                    onChange={(color, index, attr) => this.textBlockChange(color.hex, this.props.index.toString(), 'stroke_color')}
                                    onChangeComplete={() => bookStore.saveBook()}
                            />
                        </div> : null }
                    </div>
                </Stack>
            </CardContent>
            <CardActions>
                <Button size="small" 
                    onClick={(event, index) => this.deleteTextBlock(event, this.props.index.toString())}>
                        Delete Block
                </Button>
            </CardActions>
        </Card>
    );
  }
}

export default observer(TextBlockEditor)
