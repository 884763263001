import React from "react";
import Modal from '@material-ui/core/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InlineEdit from 'react-edit-inplace';
import TabPanel from "../TabPanel";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Tags from "../Tags";
import Publishing from "../Publishing";
import uiStore from "../../stores/UiStore";
import bookStore from "../../stores/BookStore";
import { observer } from 'mobx-react';
import Dropzone from 'react-dropzone';
import {uploadCover} from '../../services/BookService';

function EditBookModal(props) {

    const Item = styled(Paper)(({ theme }) => ({
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.secondary,
      }));

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        height: 800,
        overflow: 'auto',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const uploadCoverImage = (files) =>{
        var formData = new FormData();
        formData.append('file', files[0]);
    
        uploadCover(bookStore.bookData.id, formData).then(function(data){
            console.log(data);
            bookStore.uploadCoverImage(data.public_url);
        }.bind(this));
    }

    return (
        <div>
            <Modal
                open={props.open}
                onClose={props.handleClosed}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid item xs={12}>
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={uiStore.activeTab} onChange={uiStore.setTab}>
                                <Tab label="Edit Book Info" {...a11yProps(0)} />
                                <Tab label="Publishing" {...a11yProps(1)} />
                            </Tabs>
                            </Box>
                            <TabPanel value={uiStore.activeTab} index={0}>
                            <Stack spacing={2}>
                                <Item>
                                <InlineEdit
                                    text={bookStore.bookData.title}
                                    change={(data) => bookStore.inlineEditMade(data)}
                                    paramName="title"
                                />
                                </Item>
                                <Item>
                                <InlineEdit
                                    text={bookStore.bookData.author}
                                    change={(data) => bookStore.inlineEditMade(data)}
                                    paramName="author"
                                />
                                </Item>
                                <Item>              
                                <InlineEdit
                                    text={bookStore.bookData.description}
                                    change={(data) => bookStore.inlineEditMade(data)}
                                    paramName="description"
                                />
                                <Tags onChange={(event) => bookStore.setTags(event.target.value)}
                                tags={bookStore.bookData.tag_list} bookId={bookStore.bookData.id} />
                                </Item>
                                <Item>
                                    Cover Image
                                <Dropzone onDrop={uploadCoverImage} accept="image/png, image/jpeg, image/jpg">
                                    {({ getRootProps, getInputProps }) => (
                                        <img src={bookStore.bookData.image_url} height="300" width="300" {...getRootProps({ className: "dropzone" })}/>
                                    )}
                                </Dropzone> 
                                </Item>
                            </Stack>
                            </TabPanel>
                            <TabPanel value={uiStore.activeTab} index={1}>
                                <Publishing/>
                            </TabPanel>
                        </Box>
                    </Grid>
                </Box>
            </Modal>
        </div>
    );
}
export default observer(EditBookModal);
