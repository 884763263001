import React from "react"
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import bookStore from "../stores/BookStore";
import { getVersions, postVersion } from '../services/BookService';
import { observer } from 'mobx-react';

const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  }));
  

function Publishing() {

    const publishVersion = () =>{
        postVersion(bookStore.bookData.id).then(function(data){
            if (bookStore.bookData.publish_versions.length > 0){
                bookStore.bookData.publish_versions[bookStore.bookData.publish_versions.length-1].status = 'canceled'; // cancel the previous version
            }
            bookStore.bookData.publish_versions.push(data.publish_version);
            console.log(bookStore.bookData.publish_versions);
        });
    }

    return (
        <Stack spacing={2}>
            <Item>
                {bookStore.bookData.publish_versions.length == 0
                ? 'This book is unpublished. Only you can see it.': bookStore.bookData.publish_versions[0].status
                    .replace (/^[-_]*(.)/, (_, c) => c.toUpperCase())
                    .replace (/[-_]+(.)/g, (_, c) => ' ' + c.toUpperCase())
                    }
            </Item>
            <Item>              
                <Button size="small" onClick={publishVersion}>Submit New Version for Publishing</Button>
            </Item>
            {bookStore.bookData.publish_versions.length == 0
            ? 'This book is unpublished. Only you can see it.'
            : bookStore.bookData.publish_versions.map((version) => (
            <Item key={version.id}>
                <b>Submitted: </b> {new Date(version.created_at).toLocaleDateString()}
                <Divider />
                <b>Status: </b>
                {version.status
                    .replace (/^[-_]*(.)/, (_, c) => c.toUpperCase())
                    .replace (/[-_]+(.)/g, (_, c) => ' ' + c.toUpperCase())}
            </Item>
            ))}
        </Stack>
    );

}

export default observer(Publishing);