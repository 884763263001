import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Dropzone from 'react-dropzone';
import bookStore from '../../stores/BookStore';
import uiStore from '../../stores/UiStore';

import { observer } from 'mobx-react';


function PageHeader(props) {
    return (
        <Box
            sx={{
            display: 'flex',
            alignItems: 'center',
            width: 'fill',
            borderRadius: 1,
            bgcolor: 'background.paper',
            color: 'text.secondary',
            '& svg': {
                m: 1.5,
            },
            '& hr': {
                mx: 0.5,
            },
            }}
            className="page-header"
            >
                
        </Box>
  );
}
export default observer(PageHeader);