export function addDeliverable(projectId, payload){
    return fetch('/projects/' + projectId + '/deliverables', jsonRequestOptions('POST', JSON.stringify(payload)))
    .then(response => response.json());
}

export function editDeliverable(projectId, id, payload){
    return fetch('/projects/' + projectId + '/deliverables/' + id, jsonRequestOptions('PUT', JSON.stringify(payload)))
    .then(response => response.json());
}

export function deleteDeliverable(projectId, id){
    return fetch('/projects/' + projectId + '/deliverables/' + id, jsonRequestOptions('DELETE', null))
    .then(response => response.json());
}

function jsonRequestOptions(action, body){
  const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
  const requestOptions = {
    method: action,
    headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': csrf },
    body: body
  };
  return requestOptions;
}
