import React from "react";
import update from 'immutability-helper'
import { useCallback, useState } from 'react'
import { PageThumbnail } from './PageThumbnail'
import Stack from '@mui/material/Stack';
import bookStore from '../stores/BookStore';
import { observer } from 'mobx-react';

const style = {
  width: 400,
}
export const PageList = observer(({bookPages, updatePosition}) => {
  {
    const [pages, setPages] = useState(bookPages);
    const movePage = useCallback((dragIndex, hoverIndex) => {
      setPages((prevPages) =>
        update(prevPages, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevPages[dragIndex]],
          ],
        }),
      )
    }, [])
    var selectedIndex = 0;

    const renderPages = useCallback((page, index) => {
      return (
        <div key={page.id} className={`page-item ${selectedIndex == index ? "" : ""}`}>
          <PageThumbnail
            key={page.id}
            index={index}
            id={page.id}
            background_image={page.background_image}
            movePage={movePage}
          />
        </div>
      )
    }, [])
    return (
      <>
        <Stack spacing={2} style={{maxHeight: 1200, overflow: 'auto'}}>
            {bookStore.bookData.pages.map((page, i) => renderPages(page, i))}
        </Stack>
      </>
    )
  }
})

// export default PageList;