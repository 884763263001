import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import { SketchPicker } from 'react-color';
import { deleteTextBlock } from '../../services/BlockService';
import bookStore from '../../stores/BookStore';
import uiStore from '../../stores/UiStore';
import { observer } from 'mobx-react';

function TextBlockHeader() {
    const textBlocks = bookStore.bookData.pages[uiStore.currentPage].text_blocks;
    // find object in array by id
    const textBlock = bookStore.bookData.pages[uiStore.currentPage].text_blocks.find(
        (obj) => obj.id == uiStore.selectedBlock.id) || { font_size: 0, font_color: '#000000', stroke_color: '#000000' };
    const [displayFontColorPicker, setDisplayFontColorPicker] = useState(false);
    const [displayStrokeColorPicker, setDisplayStrokeColorPicker] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const textBlockChange = (value, attr) => {
        textBlock[attr] = value;
    };

    const fontColorStyles = uiStore.fontColors(textBlock.font_color);
    const strokeColorStyles = uiStore.fontColors(textBlock.stroke_color);

    const handleFontColorClick = (event) => {
        setDisplayFontColorPicker(!displayFontColorPicker);
        setAnchorEl(event.currentTarget);
    };

    const handleFontColorClose = () => {
        setAnchorEl(null);
        setDisplayFontColorPicker(false);
    };

    const handleStrokeColorClick = (event) => {
        setDisplayStrokeColorPicker(!displayStrokeColorPicker);
        setAnchorEl(event.currentTarget);
    };

    const handleStrokeColorClose = () => {
        setAnchorEl(null);
        setDisplayStrokeColorPicker(false);
    };

    const handleDeleteTextBlock = () => {
        // find index of object in array by id
        const index = textBlocks.findIndex((obj) => obj.id == textBlock.id);
        deleteTextBlock(textBlocks[index].id)
        .then(function(data){
            uiStore.setSelectedBlock({ id: null, type: 'stage' });
            textBlocks.splice(index, 1);
            bookStore.bookData.pages[uiStore.currentPage].text_blocks = textBlocks;
        }.bind(this));
    };

    return (
        <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: 'fill',
                borderRadius: 1,
                bgcolor: 'background.paper',
                color: 'text.secondary',
                '& svg': {
                  m: 1.5,
                },
                '& hr': {
                  mx: 0.5,
                },
              }}
              className="text-block-header"
            >
        <TextField
            id="text-font-size"
            label="Size"
            type="number"
            size="small"
            style = {{width: 100}}
            value={textBlock.font_size}
            onChange={(event, attr) => textBlockChange(event.target.value, 'font_size')}
            InputLabelProps={{
                shrink: true,
            }}
            onBlur={() => bookStore.saveBook()}
        />
        <Divider orientation="vertical" flexItem />
        <Select
            labelId="font-family"
            id="font-family"
            style = {{width: 100, padding: "4px 0"}}
            value={textBlock.font_family}
            label="Font Family"
            onChange={(event, attr) => textBlockChange(event.target.value, 'font_family')}
            onClose={() => bookStore.saveBook()}
        >
            {uiStore.fontStyles().map((style, index) => (
                <MenuItem key={index} value={style}>{style}</MenuItem>
            ))}
        </Select>
        <Divider orientation="vertical" flexItem />
        <Select
            labelId="text-align"
            id="text-align"
            style = {{width: 100, padding: "4px 0"}}
            value={textBlock.text_align}
            label="Align"
            onChange={(event, attr) => textBlockChange(event.target.value, 'text_align')}
            onClose={() => bookStore.saveBook()}
        >
            <MenuItem value={'left'}>Left</MenuItem>
            <MenuItem value={'right'}>Right</MenuItem>
            <MenuItem value={'center'}>Center</MenuItem>
        </Select>
        <Divider orientation="vertical" flexItem />
        <TextField
            id="text-width"
            label="Width"
            type="number"
            size="small"
            style = {{width: 100}}
            value={textBlock.width}
            onChange={(event, attr) => textBlockChange(event.target.value, 'width')}
            InputLabelProps={{
                shrink: true,
            }}
            onBlur={() => bookStore.saveBook()}
        />
        <Divider orientation="vertical" flexItem />
        <div>
            <div style={{ ...fontColorStyles.swatch, padding: "12px 4px", width:"100px" }} onClick={ handleFontColorClick }>
                Font Color: <div style={ fontColorStyles.color } />
                </div>
                { displayFontColorPicker ? <div style={ fontColorStyles.popover }>
                <Popover
                    id={displayFontColorPicker ? 'simple-popover' : undefined}
                    open={displayFontColorPicker}
                    anchorEl={anchorEl}
                    onClose={handleFontColorClose}
                    anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                    }}
                >
                    <div style={ fontColorStyles.cover } onClick={ handleFontColorClose }/>
                        <SketchPicker
                            color={ textBlock.font_color }
                            onChange={(color, attr) => textBlockChange(color.hex, 'font_color')}
                            onChangeComplete={() => bookStore.saveBook()}
                        />
                </Popover>
            </div> : null }
        </div>
        <Divider orientation="vertical" flexItem />
        <div>
            <div style={{ ...fontColorStyles.swatch, padding: "12px", width:"100px" }} onClick={ handleStrokeColorClick }>
                Stroke Color: <div style={ strokeColorStyles.color } />
                </div>
                { displayStrokeColorPicker ? <div style={ strokeColorStyles.popover }>
                <Popover
                    id={displayStrokeColorPicker ? 'simple-popover' : undefined}
                    open={displayStrokeColorPicker}
                    anchorEl={anchorEl}
                    onClose={handleStrokeColorClose}
                    anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                    }}
                >
                    <div style={ fontColorStyles.cover } onClick={ handleStrokeColorClose }/>
                    <SketchPicker
                        color={ textBlock.stroke_color }
                        onChange={(color, attr) => textBlockChange(color.hex, 'stroke_color')}
                        onChangeComplete={() => bookStore.saveBook()}
                    />
                </Popover>
            </div> : null }
        </div>
        <Divider orientation="vertical" flexItem />
        <TextField
            id="stroke-width"
            label="Stroke"
            type="number"
            size="small"
            style = {{width: 100}}
            value={textBlock.stroke_width}
            onChange={(event, attr) => textBlockChange(event.target.value, 'stroke_width')}
            InputLabelProps={{
                shrink: true,
            }}
            onBlur={() => bookStore.saveBook()}
        />
        {/* <Divider orientation="vertical" flexItem />
        <TextField
            id="text-body"
            label="Text"
            type="text"
            size="small"
            style = {{width: 150}}
            value={textBlock.body}
            onChange={(event, attr) => textBlockChange(event.target.value, 'body')}
            InputLabelProps={{
                shrink: true,
            }}
            onBlur={() => bookStore.saveBook()}
        /> */}
        <Divider orientation="vertical" flexItem />
        <Button size="small" 
            onClick={(event) => handleDeleteTextBlock()}>
                Delete Block
        </Button>
    </Box>
    );
}
export default observer(TextBlockHeader);


