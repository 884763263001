import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import IllustratorAccordion from './IllustratorAccordion';


export default function SideBar() {
  const [state, setState] = React.useState({
    right: false,
  });

  const openDrawer = (anchor) => (event) => {
    setState({ ...state, [anchor]: true });
  };

  const closeDrawer = (anchor) => (event) => {
    setState({ ...state, [anchor]: false });
  };

  const list = () => (
    <Box
      sx={{ width: 450 }}
      role="presentation"
    >
        <IllustratorAccordion />
    </Box>
  );

  return (
    <div>
      {['Page Background'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={openDrawer('right', true)}>{anchor}</Button>
          <Drawer
            anchor={'right'}
            open={state['right']}
            onClose={ closeDrawer('right', false) }
          >
            {list()}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}