import React from "react"
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Checkbox from '@mui/material/Checkbox';

class WritingBlockEditor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };
        console.log(props);
    }

    clearWriting = (event, index) => {
        this.props.clearWriting();
    };

    deleteWritingBlock = () => {
        this.props.deleteWritingBlock(this.props.index);
    };

    showSignal = (event, index) => {
        // this.props.showSignal();
    };

    render () {
        return (
            <div>Click anywhere on the stage to draw
                <Checkbox
                  checked={this.props.writingBlock.show_signal}
                  onChange={this.showSignal}
                /> Show Signal on mobile
                <Button onClick={() => this.clearWriting()} title={'Clear Writing'}>Clear Writing</Button>
                <Button onClick={() => this.deleteWritingBlock()} title={'Delete Writing Block'}><DeleteIcon /></Button>
            </div>
        );
    }
}

export default WritingBlockEditor
