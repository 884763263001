import React from 'react';
import { Stage, Layer, Rect, Text, Transformer } from 'react-konva';
import URLImage from '../components/UrlImage';
import TextBlock from '../components/TextBlock'
import bookStore from "../stores/BookStore";
import uiStore from "../stores/UiStore";
import { observer } from 'mobx-react';
import { toJS, runInAction } from 'mobx';
import Konva from 'konva';

const BookCanvas = () => {
  const [selectedId, selectShape] = React.useState(null);

  const checkDeselect = (e) => { // deselect when clicked on empty area
    const clickedOnEmpty = e.target.index === 0;
    if (clickedOnEmpty) {
      selectShape(null);
      uiStore.setSelectedBlock({ id: null, type: 'stage' });
    }
  };

  return (
    <Stage
      width={850} height={1100}
      onTouchStart={checkDeselect}
      onClick={checkDeselect}
    >
      <Layer>
        <URLImage src={bookStore.bookData.pages[uiStore.currentPage].background_image}
          width={850} height={1100}
          onClick={(e) => {checkDeselect}}
          draggable={false}
        />
        {bookStore.bookData.pages[uiStore.currentPage].text_blocks.map((value, i) => {
            return <TextBlock
            key={i}
            setTextValue={(value) => {
              bookStore.setTextBlockAttr(uiStore.currentPage, value, i, 'body');
              bookStore.saveBook();
            }}
            blockProps={value}
            isSelected={value.id === selectedId}
            onSelect={() => {
              selectShape(value.id);
              uiStore.setSelectedBlock({ id: value.id, type: 'text-block' });
            }}
            onChange={(newAttrs) => {
              bookStore.setTextBlockAttr(uiStore.currentPage, newAttrs.x, i, 'x');
              bookStore.setTextBlockAttr(uiStore.currentPage, newAttrs.y, i, 'y');
              if(newAttrs.width && newAttrs.fontSize){
                bookStore.setTextBlockAttr(uiStore.currentPage, newAttrs.width, i, 'width');
                bookStore.setTextBlockAttr(uiStore.currentPage, newAttrs.fontSize, i, 'font_size');
              }
              bookStore.saveBook();
            }}
          />
          })}
          {bookStore.bookData.pages[uiStore.currentPage].image_blocks.map((value, i) => {
            return <URLImage 
              key={i}
              src={value.triggered ? value.after_state.image_url : value.before_state.image_url} 
              x={parseFloat(value.before_state.x)} 
              y={parseFloat(value.before_state.y)} 
              width={parseFloat(value.before_state.width)} 
              height={parseFloat(value.before_state.height)}
              isSelected={value.id === selectedId}
              onSelect={() => {
                selectShape(value.id);
                uiStore.setSelectedBlock({ id: value.id, type: 'image-block' });
              }}
              onChange={newAttrs => {
                if (newAttrs.width) {
                  bookStore.setImageBlockAttr(uiStore.currentPage, newAttrs.width * newAttrs.scaleX, i, 'before_state', 'width');
                  bookStore.setImageBlockAttr(uiStore.currentPage, newAttrs.width * newAttrs.scaleX, i, 'after_state', 'width');
                  bookStore.setImageBlockAttr(uiStore.currentPage, newAttrs.height * newAttrs.scaleY, i, 'before_state', 'height');
                  bookStore.setImageBlockAttr(uiStore.currentPage, newAttrs.height * newAttrs.scaleY, i, 'after_state', 'height');
                }
                bookStore.setImageBlockAttr(uiStore.currentPage, newAttrs.x, i, 'before_state', 'x');
                bookStore.setImageBlockAttr(uiStore.currentPage, newAttrs.x, i, 'after_state', 'x');
                bookStore.setImageBlockAttr(uiStore.currentPage, newAttrs.y, i, 'before_state', 'y');
                bookStore.setImageBlockAttr(uiStore.currentPage, newAttrs.y, i, 'after_state', 'y');
                bookStore.saveBook()
              }}
              draggable={true}
              />
          })}
      </Layer>
    </Stage>
  );
};

export default observer(BookCanvas)
