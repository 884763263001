// text blocks

export function addTextBlock(pageId){
    return fetch('/pages/' + pageId + '/text_blocks', requestOptions('POST'))
    .then(response => response.json())
}

export function deleteTextBlock(blockId){
    return fetch('/text_blocks/' + blockId, requestOptions('DELETE'))
      .then(response => response.json())
}

// image blocks

export function addImageBlock(pageId){
    return fetch('/pages/' + pageId + '/image_blocks', requestOptions('POST'))
    .then(response => response.json())
}

export function deleteImageBlock(blockId){
    return fetch('/image_blocks/' + blockId, requestOptions('DELETE'))
    .then(response => response.json())
}

export function uploadImage(files, blockId, imageType){
    var formData = new FormData();
    formData.append('file', files[0]);
    formData.append('image_type', imageType);
    const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
    const requestOptions = {
        method: 'POST',
        headers: { 'X-CSRF-Token': csrf },
        body: formData
    };
    return fetch('/image_blocks/'+ blockId +'/upload_block_image', requestOptions)
        .then(response => response.json())
}

// audio blocks

export function addAudioBlock(pageId){
    return fetch('/pages/' + pageId + '/audio_blocks', requestOptions('POST'))
    .then(response => response.json())
}

export function deleteAudioBlock(blockId){
    return fetch('/audio_blocks/' + blockId, requestOptions('DELETE'))
    .then(response => response.json())
}

// writing blocks

export function addWritingBlock(pageId){
    return fetch('/pages/' + pageId + '/writing_blocks', requestOptions('POST'))
    .then(response => response.json())
}

export function deleteWritingBlock(blockId){
    return fetch('/writing_blocks/' + blockId, requestOptions('DELETE'))
    .then(response => response.json())
}

export function saveSignal(blockId, payload){
    return fetch('/writing_blocks/' + blockId, requestOptions('PUT', payload))
    .then(response => response.json())
}

// utility

function requestOptions(action, body){
    const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
    const requestOptions = {
      method: action,
      headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': csrf },
      body: body
    };
    return requestOptions;
}
