import React from "react"
import PropTypes from "prop-types"
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import Dropzone from 'react-dropzone'
import CircularProgress from '@mui/material/CircularProgress';
import { uploadImage, deleteImageBlock } from '../services/BlockService';
import bookStore from "../stores/BookStore";
import uiStore from "../stores/UiStore";

class ImageBlockEditor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          uploading: false
        };
      }

    changeTargetWord = (e) => {
        this.props.changeTargetWord(e);
    };

    uploadBlockImage(files, blockId, imageType){
        this.setState({uploading: true});
        uploadImage(files, blockId, imageType).then(function(data){
            var index = bookStore.bookData.pages[uiStore.currentPage].image_blocks.map(x => { return x.id; }).indexOf(blockId);
            if (imageType == 'before'){
                bookStore.bookData.pages[uiStore.currentPage].image_blocks[index].before_state.image_url = data.public_url;
            }else{
                bookStore.bookData.pages[uiStore.currentPage].image_blocks[index].after_state.image_url = data.public_url;
            }
            this.setState({uploading: false});
        }.bind(this));
    }

    deleteImageBlock = (event, index) => {
        var imageBlocks = bookStore.bookData.pages[uiStore.currentPage].image_blocks;
        deleteImageBlock(imageBlocks[index].id)
        .then(function(data){
            imageBlocks.splice(index, 1);
            bookStore.bookData.pages[uiStore.currentPage].image_blocks = imageBlocks;
          }.bind(this));
    };

    render () {
        return (
            <Card sx={{ minWidth: 275 }} variant="outlined">
                <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        Image Block
                    </Typography>
                    <Stack spacing={2}>
                        {this.state.uploading == true &&
                        <CircularProgress />
                    }
                        <TextField fullWidth size="small" label="Target Word" type="text" name={this.props.index.toString()} value={this.props.imageBlock.target_word} onChange={this.changeTargetWord} />
                        <Stack direction="row">
                            <Dropzone onDrop={(files, id, ImageType) => {this.uploadBlockImage(files, this.props.imageBlock.id, "before")}} accept="image/png, image/jpeg, image/jpg" >
                                {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps({ className: "dropzone" })}>
                                    <input {...getInputProps()} />
                                    Main block image. Drag'n'drop files, or click.
                                </div>
                                )}
                            </Dropzone>
                            <img src={this.props.imageBlock.before_state.image_url} height="100" width="100"/>
                        </Stack>
                        <Stack direction="row">
                            <Dropzone onDrop={(files, id, ImageType) => {this.uploadBlockImage(files, this.props.imageBlock.id, "after")}} accept="image/png, image/jpeg, image/jpg" >
                                {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps({ className: "dropzone" })}>
                                    <input {...getInputProps()} />
                                    On change image. Drag'n'drop files, or click
                                </div>
                                )}
                            </Dropzone>
                            <img src={this.props.imageBlock.after_state.image_url} height="100" width="100"/>
                        </Stack>
                    </Stack>
                </CardContent>
            <CardActions>
                <Button size="small"
                onClick={(event, index) => this.deleteImageBlock(event, this.props.index.toString())}>
                    Delete Block
                </Button>
            </CardActions>
        </Card>
        );
    }
}

ImageBlockEditor.propTypes = {
  imageBlock: PropTypes.object,
  index: PropTypes.number,
  currentPageId: PropTypes.number
};
export default ImageBlockEditor
