import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import bookStore from '../../stores/BookStore';
import uiStore from '../../stores/UiStore';
import Dropzone from 'react-dropzone';
import { generateImage} from '../../services/PageService';
import { getPageMessages, sendPageMessage } from '../../services/PageService';
import { observer } from 'mobx-react';
import { set } from 'mobx';

function IllustratorAccordion() {

  React.useEffect(() => {
    loadMessages();
  }, []);

  var [messages, setMessages] = React.useState([]);
  var [socket, setSocket] = React.useState(null);
  var [conversationId, setConversationId] = React.useState("");
  var [isTyping, setIsTyping] = React.useState(false);
  const [generatingImage] = React.useState(null);

    const handleGenerateImage = () => {
        uiStore.generatingImage = true;
        generateImage(bookStore.bookData.pages[uiStore.currentPage].id, uiStore.prompt).then(function(data){
            bookStore.bookData.pages[uiStore.currentPage].background_image = data.public_url;
            uiStore.generatingImage = false;
        }.bind(this));
    }

  function loadMessages(){
    console.log("loadMessages");
    getPageMessages(bookStore.bookData.pages[uiStore.currentPage].id).then(function(data){
      uiStore.setPageMessages(data.messages);
      setConversationId(data.conversation.id);
      console.log(data.conversation.id);
       // implememtation inspired from https://dev.to/lucaskuhn/a-simple-guide-to-action-cable-2dk2
      setSocket(App.cable.subscriptions.create({ channel: "ConversationsChannel", id: data.conversation.id }, {
        connected() {
          console.log("Connected to the channel:", this);
        },
        disconnected() {
          console.log("Disconnected");
        },
        received(data) {
          console.log("Received some data:", data);
          if (data['type'] == 'typing') {
            if (data.username == uiStore.currentUser.username){ return;}
            setIsTyping(true);
            setTimeout(() => {
                setIsTyping(false);
            }, "3000");
            }else{
              uiStore.appendToPageMessages(data);
            }
        }
      }));
    }.bind(this));
  }

  function sendMessage() {
    sendPageMessage(conversationId, JSON.stringify({
      message: {
        conversation_id: conversationId,
        page_id: bookStore.bookData.pages[uiStore.currentPage].id,
        body: uiStore.newPageMessage
      }
    })).then(function(data){
      // uiStore.appendToPageMessages(JSON.parse(data.message));
      uiStore.setNewPageMessage('');
    }.bind(this));
  }

  function newMessageFieldChange(event) {
    uiStore.setNewPageMessage(event.target.value);
    socket.send({ type: 'typing', id: conversationId, username: uiStore.currentUser.username });
  }

  return (
    <div>
      <Typography variant='h4' align='center' padding={1}>
        Page Background
      </Typography>
      <Typography variant='body2' padding={1}>
        Use AI to generate a background. Images should be 1024x1024 to enable square and portrait books.
        Portrait books are cropped in from the left and right sides of the image.
      </Typography>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Page Background Versions</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='body2'>
            Click any page to restore that version
          </Typography>
          {bookStore.bookData.pages[uiStore.currentPage].page_versions.map((version, i) => {
            return <img src={version.background_image}
              height="100" width="100" key={i} 
              onClick={() => bookStore.setBackgroundImage(uiStore.currentPage, version.background_image)}
              />
            })}
            <Dropzone onDrop={(files, currentPage) => bookStore.uploadPageImage(files, uiStore.currentPage)}
                accept="image/png, image/jpeg, image/jpg">
                    {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        Page background image. Drag'n'drop files, or click.
                        <img src={bookStore.bookData.pages[uiStore.currentPage].background_image} height="150" width="150"/>
                        </div>
                    )}
            </Dropzone>
            <Typography variant='body2'>
              Or use AI to generate a new image based on your prompt
            </Typography>
            <TextField label="Prompt"
                      value={uiStore.prompt || ''}
                      placeholder='Describe what you want and we will try to generate an image. This will take about a minute.'
                      multiline
                      rows={3}
                      variant="standard"
                      fullWidth
                      onChange={(event) => uiStore.promptChange(event.target.value)}
                  />
                  {uiStore.generatingImage ? (
                      <CircularProgress />
                  ) : (
                      <Button variant="text" onClick={handleGenerateImage}>Generate Image</Button>
                  )}
        </AccordionDetails>
      </Accordion>
      {/* <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Page Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='body2' padding={1}>
            What are you going for on this page?
          </Typography>
          <TextField
            id="page-description"
            label="Description"
            type="text"
            placeholder='Enter page description here. Use your imagination!'
            multiline
            fullWidth
            value={bookStore.bookData.pages[uiStore.currentPage].description || ''}
            onChange={(event) => bookStore.handlePageChange(event.target.value, uiStore.currentPage, 'description')}
            InputLabelProps={{
                shrink: true,
            }}
            onBlur={() => bookStore.saveBook()}
        /> 
        </AccordionDetails>
      </Accordion> */}
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Chat with the illustrator</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant='body2'>
            Did you know you can work with illustrators right in the Infinite Books platform?
          </Typography>
          {bookStore.bookData.projects.length > 0 ? 
          <div>
            { uiStore.pageMessages.length > 0 ?
              uiStore.pageMessages.map((message, i) => {
                  return <li key={i}><span>{message.username}:</span> {message.body}</li>
              })
            : null}
          { isTyping ? <p>Someone is typing...</p> : null}
            <TextField
              id="send-message"
              label="Send Message"
              type="text"
              placeholder='Enter text here to chat.'
              multiline
              fullWidth
              value={uiStore.newPageMessage || ''}
              onChange={newMessageFieldChange}
            /> 
            <Button variant="contained" onClick={sendMessage}>Send</Button>
          </div>
          : 
          <Link href="/illustrators">Find An Illustrator</Link>
          }
          </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default observer(IllustratorAccordion);